import { Component,OnInit } from '@angular/core';
import {CookieService} from "ngx-cookie-service";

import { SSOLogonService } from '../esamui/service/ssologon.service';


@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
})
export class AppLoginComponent implements OnInit  {
  cookieValue = 'UNKNOWN';
  constructor(private cookieService : CookieService, private ssologonservice: SSOLogonService ) { 
  }

  getLoggedSSOUserAttributes(cookieValue: string) {
    this.ssologonservice.getLoggedSSOUserAttributes(cookieValue).subscribe(
      result => {
        console.log('RESULT identity/attributes: ' + result);
        // window.location.href='https://sso.dev.det.nsw.edu.au/sso/UI/Login?goto=http://myhost.dev.det.nsw.edu.au:4200/';
        // this.router.navigate(['/ssoLogin']);
        //   if (result.valid == 'false') {
        //     console.log('FALSE VALUE');
        //   } else if (result.valid == 'true') {
        //     console.log('TRUE VALUE');
        //   }
      }, error => {console.log('ERROR' + error);});
    // this.cookieService.set( 'iPlanetDirectoryPro', this.cookieService.get('iPlanetDirectoryPro') );
    // this.cookieValue = this.cookieService.get('iPlanetDirectoryPro');
    // console.log('COOKIE ' + this.cookieValue);
  }
  
  getLogin() {
  
  
    this.ssologonservice.getLogin() ; //(
    // result => {
    //   console.log('RESULT' + result);
    //
    //   // this.router.navigate(['/ssoLogin']);
    //   //   if (result.valid == 'false') {
    //   //     console.log('FALSE VALUE');
    //   //   } else if (result.valid == 'true') {
    //   //     console.log('TRUE VALUE');
    //   //   }
    // }, error => {console.log('ERROR' + error);});
    // this.cookieService.set( 'iPlanetDirectoryPro', this.cookieService.get('iPlanetDirectoryPro') );
    this.cookieValue = this.cookieService.get('iPlanetDirectoryPro');
    console.log('COOKIE ' + this.cookieValue);
  }

  ngOnInit() {

  

  }

}
