import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
//import {HomeComponent} from './esamui/components/home/home.component';
//import {VisitListComponent} from './demo/view/visitlist.component';
//import {DashboardComponent} from './demo/view/dashboard.component';
/*import {FormLayoutDemoComponent} from './demo/view/formlayoutdemo.component';
import {PanelsDemoComponent} from './demo/view/panelsdemo.component';
import {OverlaysDemoComponent} from './demo/view/overlaysdemo.component';
import {MenusDemoComponent} from './demo/view/menusdemo.component';
import {MessagesDemoComponent} from './demo/view/messagesdemo.component';
import {MiscDemoComponent} from './demo/view/miscdemo.component';
import {EmptyDemoComponent} from './demo/view/emptydemo.component';*/
/*import {PreVisitDetailsComponent} from './demo/view/previsitdetails.component';
import {PostVisitDetailsComponent} from './demo/view/postvisitdetails.component';
import {PeerReviewComponent} from './demo/view/peerreview.component';
import {VisitListComponent} from './demo/view/visitlist.component';
import {FinishedVisitListComponent} from './demo/view/finishedvisitlist.component';
import {EmailtemplateComponent} from './demo/view/emailtemplate.component';
import {FDCPreVisitDetailsComponent} from './demo/view/fdcprevisitdetails.component';
import {FDCPostVisitDetailsComponent} from './demo/view/fdcpostvisitdetails.component';
import {FDCVisitlistComponent} from './demo/view/fdcvisitlist.component';*/
//import {SurveyFormComponent} from './demo/components/survey/surveyform.component';

//import {ChartsDemoComponent} from './demo/view/chartsdemo.component';
//import {FileDemoComponent} from './demo/view/filedemo.component';
//import {DocumentationComponent} from './demo/view/documentation.component';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppDocumentComponent } from './pages/app.doc.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { AppExternalAccessComponent } from './pages/app.externalaccess.component';

//import { AppSurveyComponent } from './pages/app.survey.component';
//import { AppSurveyFormComponent } from './pages/app.surveyform.component';

//import { AppDraftResponseComponent } from './pages/app.draftresponse.component';
import { AppExtWelcomeComponent } from './pages/app.extwelcome.component';
/*import {InputDemoComponent} from './demo/view/inputdemo.component';
import {ButtonDemoComponent} from './demo/view/buttondemo.component';
import {TableDemoComponent} from './demo/view/tabledemo.component';
import {ListDemoComponent} from './demo/view/listdemo.component';
import {TreeDemoComponent} from './demo/view/treedemo.component';
import {DisplayComponent} from './utilities/display.component';
import {ElevationComponent} from './utilities/elevation.component';
import {FlexboxComponent} from './utilities/flexbox.component';
import {GridComponent} from './utilities/grid.component';
import {IconsComponent} from './utilities/icons.component';
import {WidgetsComponent} from './utilities/widgets.component';
import {SpacingComponent} from './utilities/spacing.component';
import {TypographyComponent} from './utilities/typography.component';
import {TextComponent} from './utilities/text.component';
import {AppCrudComponent} from './pages/app.crud.component';
import {AppCalendarComponent} from './pages/app.calendar.component';
import {AppInvoiceComponent} from './pages/app.invoice.component';
import {AppHelpComponent} from './pages/app.help.component';
import {MediaDemoComponent} from './demo/view/mediademo.component';*/
import { OAuth2ComponentComponent } from './esamui/security/oauth2-component/oauth2-component.component';
import { ConfirmationMessageComponent } from './pages/app.confirmationmsg.component';
import { AppSurveyComponent } from './pages/app.survey.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            { path: '', redirectTo: 'login', pathMatch: 'full' },
            { path: 'secure/aad', component: OAuth2ComponentComponent },
            { path: 'oauth2/logout', redirectTo: 'login' },

            {
                path: 'main', component: AppMainComponent,
                children: [
                    { path: 'maindash', redirectTo: 'visitlist' },
                    //  {path: 'externalaccess/steps', loadChildren: () => import('./esamui/components/servicecontext/stepsdemo.module').then(m => m.StepsDemoModule)},
                    //  {path: 'externalaccess/selfassessment', loadChildren: () => import('./esamui/components/selfassessment/stepsdemo.module').then(m => m.StepsDemoModule)},
                    //  {path: 'externalaccess/responsetodraft', loadChildren: () => import('./esamui/components/responsetodraft/steps1demo.module').then(m => m.Steps1DemoModule)},

                    //   {path: 'externalaccess', component: AppExternalAccessComponent},                                  
                    // {path: 'externalaccess/welcome', component: AppExtWelcomeComponent},
                    // {path: 'externalaccess/confirmmsg', component: ConfirmationMessageComponent}, 
                    { path: 'admin/emailtemplates', loadChildren: () => import('./esamui/components/admin/emailtemplates/emailtemplates.component.module').then(m => m.EmailtemplatesComponentModule) },
                    { path: 'admin/userdetails', loadChildren: () => import('./esamui/components/admin/userdetails/userdetails.component.module').then(m => m.UserDetailsComponentModule) },
                    { path: 'admin/servicedetail', loadChildren: () => import('./esamui/components/admin/servicedetail/servicedetail.component.module').then(m => m.ServiceDetailComponentModule) },
                    { path: 'admin/mgmtreport', loadChildren: () => import('./esamui/components/admin/mgmtreport/mgmtreport.component.module').then(m => m.MgmtReportComponentModule) },
                    { path: 'admin/asrdataupdate', loadChildren: () => import('./esamui/components/admin/asrdataupdate/ratingupdate.component.module').then(m => m.RatingUpdateComponentModule) },
                    { path: 'admin/servicecontext', loadChildren: () => import('./esamui/components/admin/servicecontext/servicecontext.component.module').then(m => m.ServiceContextComponentModule) },
                    { path: 'admin/servicefeedback', loadChildren: () => import('./esamui/components/admin/servicefeedback/servicefeedback.component.module').then(m => m.ServiceFeedbackComponentModule) },
                    { path: 'admin/survey', loadChildren: () => import('./esamui/components/admin/survey/survey.component.module').then(m => m.SurveyComponentModule) },
                    { path: 'admin/surveyform', loadChildren: () => import('./esamui/components/admin/surveyform/surveyform.component.module').then(m => m.SurveyFormComponentModule) },
                    { path: 'admin/wwcc', loadChildren: () => import('./esamui/components/admin/wwcc/wwcc.component.module').then(m => m.WwccComponentModule) },
                    { path: 'admin/trim', loadChildren: () => import('./esamui/components/admin/trim/trim.component.module').then(m => m.TrimComponentModule) },
                    { path: 'admin/qipreminder', loadChildren: () => import('./esamui/components/admin/qipreminder/qipreminder.component.module').then(m => m.QipReminderComponentModule) },
                    { path: 'admin/portal', loadChildren: () => import('./esamui/components/admin/portal/portal.module').then(m => m.PortalModule) },
                    { path: 'finishedvisitlist', loadChildren: () => import('./esamui/components/finishedasr/finishedvisitlist.component.module').then(m => m.FinishedVisitListComponentModule) },
                    { path: 'completedvisit', loadChildren: () => import('./esamui/components/completedvisits/completedvisitdetails.component.module').then(m => m.CompletedVisitDetailsComponentModule) },
                    { path: 'visitlist', loadChildren: () => import('./esamui/components/visitlist/visitlist.component.module').then(m => m.VisitListComponentModule) },
                    // {path: 'admin/email', loadChildren: () => import('./esamui/components/emailtemplate/emailtemplate.component.module').then(m => m.EmailtemplateComponentModule)},
                    { path: 'previsit', loadChildren: () => import('./esamui/components/previsit/previsitdetails.component.module').then(m => m.PreVisitDetailsComponentModule) },
                    { path: 'postvisit', loadChildren: () => import('./esamui/components/postvisit/postvisitdetails.component.module').then(m => m.PostVisitDetailsComponentModule) },
                    { path: 'peerreview', loadChildren: () => import('./esamui/components/peerreview/peerreview.component.module').then(m => m.PeerReviewComponentModule) },
                    { path: 'fdcprevisit', loadChildren: () => import('./esamui/components/fdcprevisit/fdcprevisitdetails.component.module').then(m => m.FDCPreVisitDetailsComponentModule) },
                    { path: 'fdcpostvisit', loadChildren: () => import('./esamui/components/fdcpostvisit/fdcpostvisitdetails.component.module').then(m => m.FDCPostVisitDetailsComponentModule) },
                    { path: 'fdcvisitlist', loadChildren: () => import('./esamui/components/fdcvisitlist/fdcvisitlist.component.module').then(m => m.FDCVisitlistComponentModule) },
                    { path: 'externalaccess/survey/surveyform', component: AppSurveyComponent },
                    { path: 'externalaccess/survey/appsurveyform', loadChildren: () => import('./esamui/components/survey/surveyform.component.module').then(m => m.AppSurveyFormComponentModule) }
                ]

            },
            { path: 'documentation', component: AppDocumentComponent },
            { path: 'error', component: AppErrorComponent },
            { path: 'assets/config', component: AppErrorComponent },
            { path: 'access', component: AppAccessdeniedComponent },
            { path: 'notfound', component: AppNotfoundComponent },
            { path: 'login', component: AppLoginComponent },
            { path: '**', redirectTo: '/notfound' }
        ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
