import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginDataService } from './esamui/service/loginData.service';
import { SSOLogonService } from './esamui/service/ssologon.service';
import { AppConfigService } from './esamui/service/AppConfigService';
import { CookieService } from 'ngx-cookie-service';
//import { UserDetailsService } from './demo/service/userDetails.service';


@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {
  userId: string;
  loggedinUser: string;
  isexternalaccess = false;
  logoutURL:string;
  clientId:any;
  constructor(public app: AppMainComponent,
    private ssologonservice: SSOLogonService, private route: Router, private activatedRoute: ActivatedRoute, private loginService: LoginDataService
    , private cookieService: CookieService, private config: AppConfigService) {
      this.logoutURL = this.config.getConfig().logoutURL;
      this.clientId = this.config.getConfig().clientId;
    }

  async ngOnInit() {


    if (this.loginService.getData() == null || this.loginService.getData() == undefined) {

      console.log(localStorage.getItem('displayLoginName'));
      //this.loginService.setAccessList([]);
      this.loginService.setData({
        firstName: localStorage.getItem('firstName'),
        surname: localStorage.getItem('surname'),
        displayLoginName: localStorage.getItem('displayLoginName'),
        email: localStorage.getItem('email'),
        //  status:localStorage.getItem('status'),
        // roleId:localStorage.getItem('roleId'),
        uid: localStorage.getItem('uid')

        //roleId: localStorage.getItem('roleId')

      });

      this.userId = this.loginService.getData().firstName + " " + this.loginService.getData().surname;
      this.loggedinUser = this.loginService.getData().uid;
      //this.role = this.loginService.getData().roleId;

    } else {

      this.userId = this.loginService.getData().firstName + "." + this.loginService.getData().surname;
      this.loggedinUser = this.loginService.getData().uid;
      //this.role = this.loginService.getData().roleId;

    }

    // await this.setAccess();
    if (this.route.url.includes("externalaccess")) {
      this.isexternalaccess = true;

    }
  }

  getLogout() {
     //this.ssologonservice.getLogout();
    // this.cookieValue = this.cookieService.get('iPlanetDirectoryPro');
    // console.log('COOKIE' + this.cookieValue);
    localStorage.clear();
    this.cookieService.deleteAll();
    const clientId = this.clientId;
    window.location.href=this.logoutURL+"&client_id="+clientId;
  }

  getlink() {
    if (localStorage.getItem('isextlanding') != undefined && localStorage.getItem('isextlanding') != null)
      return false;
    else
      return true;
  }

  openhelp() {
    // var fileURL = URL.createObjectURL("assets/helppages/Self-Assessment-GuideNN.pdf");
    window.open("assets/helpPages/Service Context and Self-Assessment Guide.pdf", "_blank");
  }

}
