import { Component,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ExternalAccessDetailsService } from '../esamui/service/externalaccessdetails.service';


@Component({
  selector: 'app-confirmationmsg',
  styleUrls: ['./app.confirmationmsg.component.scss'],
  templateUrl: './app.confirmationmsg.component.html',
  providers: [MessageService]
})
export class ConfirmationMessageComponent implements OnInit  {
  sacheck:any;
  serviceId:any;
  visitId:any;
  password:any;
  sacheckFlag:boolean = false;
  constructor(private externalaccessservice : ExternalAccessDetailsService,private router: Router,private messageService: MessageService) { 
  
  }
    ngOnInit(): void {
      localStorage.clear();
      window.onpopstate = function (e) { window.history.forward(); }
     
        
    }
    
}
