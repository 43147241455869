import { Component,OnDestroy,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginDataService } from '../esamui/service/loginData.service';
import {LogonService} from '../esamui/service/logon.service';
import {ExternalAccessDetailsService} from '../../app/esamui/service/externalaccessdetails.service'
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-externalaccess',
  styleUrls: ['./app.externalaccess.component.scss'],
  templateUrl: './app.externalaccess.component.html',
  providers: [MessageService]
})
export class AppExternalAccessComponent implements OnInit , OnDestroy {
  servicenameFlag:false;
  servicenameparam:any;
  serviceId:any;
  visitId:any;
  password:any;
  constructor(private route: ActivatedRoute,private router: Router,private loginService:LoginDataService
    , private externalaccessservice : ExternalAccessDetailsService, private messageService: MessageService
    ,private logonservice: LogonService) { 
   
  }
    ngOnDestroy(): void {
        localStorage.removeItem('isextlanding');
    }
    ngOnInit(): void {

       // localStorage.clear();

        localStorage.setItem('isextlanding','true');
        this.route.queryParams.subscribe(params => {
            this.servicenameparam = params['servicename'];
           
        });
        
    }
    validateservice()
    {

        if(this.serviceId==undefined || this.serviceId==null)
        {
            this.serviceId='';
        }
        if(this.visitId==undefined || this.visitId==null)
        {
            this.visitId='';
        }
        if(this.password==undefined || this.password==null)
        {
            this.password='';
        }
           //code to validate the service id, password and asr id
        if(this.serviceId!=null && this.serviceId.trim()!='' 
        && this.visitId!=null && this.visitId.trim()!='' 
        && this.password!=null && this.password.trim()!='')
    {
        localStorage.setItem("serviceId",this.serviceId);
        localStorage.setItem("visitId",this.visitId);
        localStorage.setItem("password",this.password);
        
        this.externalaccessservice.getServiceDetails(this.serviceId,this.visitId,this.password).subscribe(data=>{
         
            if(data!=undefined && data!=null)
            {
             
              
                //check if the url is invalid

                if(data.completeFlag!=null && data.completeFlag=='Y')
                {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Access unavailable - SA/SC form already submitted' });
                    return;
                }

                let today = new Date();
                var validtilldate = new Date(data.validEndDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
            
                if (today > validtilldate) {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Your access has expired. Please contact the Network Coordination team for assistance.' });
                    return;
                }

                localStorage.setItem('firstName',this.servicenameparam);
                localStorage.setItem('surname','');
              
              

                
                //create empty initial table records
                this.logonservice.create(this.serviceId,this.visitId).subscribe(resp=>{
                        console.log("initial data tables created");
                          //update Accessedflag
                                data.assessedFlag = 'Yes';
                                this.externalaccessservice.updateAccessDetails(data).subscribe(resp2 =>{
                                    console.log("Assessed Flag updated");

                                },err=>{
                                    console.log("Assessed Flag updated failed"); 
                                })
                        this.router.navigate(['main/externalaccess/welcome']);
                },err=>{
                    console.log("create empty initial table records failed"); 
                })

              


            }
            else{
                
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'One of the details you have entered are incorrect. Please check re-enter your ASR Number, ServiceID and password' });
            
            }
        },err=>{
            console.log("internal server error externalaccessservice.getServiceDetails ");
          // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Internal error: from server' });
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'One of the details you have entered are incorrect. Please check re-enter your ASR Number, ServiceID and password' });
            
        })
    }

       
       
     
     
        
    }
}
