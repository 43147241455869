import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from '../service/AppConfigService';
import { LoginDataService } from '../service/loginData.service';
import { IUserDetails } from '../domain/user-details';
import { IUserRole } from '../domain/user-role';
import { IRegion } from '../domain/region';

const getUrl = '/users/all';
const getforUserId = '/users';
const getAccessDetails = '/users/access';
const serviceUrlI = '/api/user-details/intId';
const serviceUrl = '/api/user-details'
const serviceUrlR = '/api/user-roles';
const serviceUrlS = '/api/user-details/status';
const serviceUrlReg = '/api/regions';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {
  baseUrl: any;

  constructor(private http: HttpClient, private config: AppConfigService, private loginService: LoginDataService) {
    this.baseUrl = this.config.getConfig().baseUrl;
  }

  getAllUsers() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Methods': 'GET',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.loginService.getToken()
      })
    };


    return this.http.get<any>(this.baseUrl + getUrl, httpOptions).pipe(
      catchError(this.handleError('getAllUsers', null))
    );

  }

  getAll(): Observable<any> {
    return this.http.get<IUserDetails[]>(this.baseUrl + serviceUrl + "/all");
  }

  getAllRole(): Observable<any> {
    return this.http.get<IUserRole[]>(this.baseUrl + serviceUrlR);
  }

  getAllStatus(): Observable<any> {
    return this.http.get<IUserDetails[]>(this.baseUrl + serviceUrlS);
  }

  getAllRegion(): Observable<any> {
    return this.http.get<IRegion[]>(this.baseUrl + serviceUrlReg);
  }


  getUserforId(userId: string) {
    // alert("userid"+userId);
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Methods': 'GET',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.loginService.getToken()
      })
    };
    return this.http.get<any>(this.baseUrl + serviceUrlI + "/" + userId, httpOptions).pipe(
      catchError(this.handleError('getUserforId', userId))
    );

  }

  getAllUsersInt(): Observable<any> {
    return this.http.get<IUserDetails[]>(this.baseUrl + serviceUrl);
  }

  getUserIntuserId(intuserId: any): Observable<any> {
    return this.http.get(this.baseUrl + serviceUrlI + '/' + intuserId);
  }

  create(data: any): Observable<any> {
    return this.http.post(this.baseUrl + serviceUrl, data);
  }

  update(data: any): Observable<any> {
    return this.http.put(this.baseUrl + serviceUrl, data);
  }


  getAccessDetails(roleId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Methods': 'GET',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + this.loginService.getToken(),
      })
    };
    //  alert("in access details"+roleId) ;
    return this.http.get<any>(this.baseUrl + getAccessDetails + "/" + roleId, httpOptions).pipe(
      catchError(this.handleError('getAccessDetails', roleId))
    );

  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      alert("error message" + JSON.stringify(error));
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
