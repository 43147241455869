import { Component,OnDestroy,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginDataService } from '../esamui/service/loginData.service';
import {LogonService} from '../esamui/service/logon.service';
import {ExternalAccessDetailsService} from '../../app/esamui/service/externalaccessdetails.service'
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-survey',
  styleUrls: ['./app.survey.component.scss'],
  templateUrl: './app.survey.component.html',
  providers: [MessageService]
})
export class AppSurveyComponent implements OnInit , OnDestroy {
  servicenameFlag:false;
  servicenameparam:any;
  serviceId:any;
  visitId:any;
  password:any;
  constructor(private route: ActivatedRoute,private router: Router,private loginService:LoginDataService
    , private externalaccessservice : ExternalAccessDetailsService, private messageService: MessageService
    ,private logonservice: LogonService) { 
   
  }
    ngOnDestroy(): void {
        localStorage.removeItem('isextlanding');
    }
    ngOnInit(): void {

        localStorage.setItem('isextlanding','true');
        this.route.queryParams.subscribe(params => {
            this.servicenameparam = params['servicename'];
           
        });
        
    }
    validateserDets()
    {

        if(this.serviceId==undefined || this.serviceId==null)
        {
            this.serviceId='';
        }
        if(this.visitId==undefined || this.visitId==null)
        {
            this.visitId='';
        }
        if(this.password==undefined || this.password==null)
        {
            this.password='';
        }
           //code to validate the service id, password and asr id
        if(this.serviceId!=null && this.serviceId.trim()!='' && this.visitId!=null && this.visitId.trim()!='' && this.password!=null && this.password.trim()!='')  {
        
        this.externalaccessservice.getServiceDetails(this.serviceId,this.visitId,this.password).subscribe(data=>{
         
            if(data!=undefined && data!=null)
            {
               
               

                //check if the url is invalid

               /* if(data.completeFlag!=null && data.completeFlag=='Y')
                {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Access unavailable - SA/SC form already sunmitted' });
                    return;
                }*/

                localStorage.setItem('firstName',this.servicenameparam);
                localStorage.setItem('surname','');
              
                localStorage.setItem("serviceId",this.serviceId);
                localStorage.setItem("visitId",this.visitId);
                localStorage.setItem("password",this.password);

                
                //create empty initial table records
              /*  this.logonservice.create(this.serviceId,this.visitId).subscribe(resp=>{
                        console.log("initial data tables created");
                        this.router.navigate(['main/externalaccess/welcome']);
                },err=>{
                   console.log(err); 
                })*/

                this.router.navigate(['main/externalaccess/survey/appsurveyform']);

            }
            else{
                
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid ASR/Service ID Details' });
            
            }
       }
       ,err=>{
            console.log(err);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Internal error: '+err });
  
        })
   // }

       
       
     
     
        
    }
}




}