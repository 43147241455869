import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IServiceEvents } from '../domain/service-events';
import { Observable } from 'rxjs';
import { AppConfigService } from './AppConfigService';

const baseUrl = '/api/generate-report';
const baseDUrl = '/api/generate-reports-desk'
const baseRUrl = '/api/generate-mgmt-report'
const uploadUrl = '/api/upload-report';

const sendemail = '/api/send-email';

@Injectable({
  providedIn: 'root'
})
export class GenerateReportService {

  serviceUrl: any;
  constructor(private http: HttpClient, private config: AppConfigService) {

    this.serviceUrl = this.config.getConfig().baseUrl;
  }

  getAll(): Observable<any> {
    return this.http.get<IServiceEvents[]>(this.serviceUrl + baseUrl);
  }


  getReport(type: string, serviceId: string, asrId: string, forACO: string, serviceType: String): Observable<any> {
    const httpOptions = {
      // 'responseType': 'blob' as 'json'
      'responseType': 'arraybuffer' as 'json'
    };

    return this.http.post(this.serviceUrl + baseUrl + "?type=" + type + "&visitId=" + asrId + "&serviceId=" + serviceId + "&forACO=" + forACO + "&serviceType=" + serviceType, null, httpOptions);
  }

  getMgmtReport(reportId: string, outputFormat: string, selectedACODetail: any[], selectedASRDetail: any[], selectedAVGDetail: any[], selectedRegDetail: any[]): Observable<any> {    const httpOptions = {
      //'responseType': 'blob' as 'json'
      'responseType': 'arraybuffer' as 'json'
    };

    return this.http.post(this.serviceUrl + baseRUrl + "?reportId=" + reportId + "&outputFormat=" + outputFormat + "&selectedACODetail=" + selectedACODetail + "&selectedASRDetail=" + selectedASRDetail + "&selectedAVGDetail=" + selectedAVGDetail + "&selectedRegDetail=" + selectedRegDetail, null, httpOptions);  }

  getdeskReport(type: string, serviceId: string, asrId: string, forACO: string, serviceType: String): Observable<any> {
    const httpOptions = {
      // 'responseType': 'blob' as 'json'
      'responseType': 'arraybuffer' as 'json'
    };

    return this.http.post(this.serviceUrl + baseDUrl + "?type=" + type + "&visitId=" + asrId + "&serviceId=" + serviceId + "&forACO=" + forACO + "&serviceType=" + serviceType, null, httpOptions);
  }



  uploadAndSendReport(type: string, serviceId: string, asrId: string, forACO: string, providerId: string, serviceType: String) {


    return this.http.post(this.serviceUrl + uploadUrl + "?type=" + type + "&visitId=" + asrId + "&serviceId=" + serviceId + "&forACO=" + forACO + "&providerId=" + providerId + "&serviceType=" + serviceType, null);


  }

  sendACOemail(visitId: string, serviceId: string, optflag: string) {
    return this.http.post(this.serviceUrl + sendemail + "/" + visitId + "/" + serviceId + "/" + optflag, null);

  }

  create(data: any): Observable<any> {
    return this.http.post(this.serviceUrl + baseUrl, data);
  }

  update(data: any): Observable<any> {
    return this.http.put(this.serviceUrl + baseUrl, data);
  }

}
