//import {NgModule} from '@angular/core';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import {FormsModule} from '@angular/forms';
//import {HttpClientModule} from '@angular/common/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { CommonModule } from '@angular/common'; 
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import { DatePipe } from '@angular/common'
import {CookieService} from 'ngx-cookie-service';
import {MessageService} from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { TokenInterceptorService } from './esamui/service/TokenInterceptorService';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import { OAuth2ComponentComponent } from './esamui/security/oauth2-component/oauth2-component.component';
//import {HomeComponent} from './esamui/components/home/home.component';
//import {VisitListComponent} from './demo/view/visitlist.component';
import {CheckboxModule} from 'primeng/checkbox';
import {InputTextModule} from 'primeng/inputtext';
import {TableModule} from 'primeng/table';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {StepsModule} from 'primeng/steps';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {AppCodeModule} from './app.code.component';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppConfigComponent } from './app.config.component';
import { AppMenuComponent} from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import {AppBreadcrumbComponent} from './app.breadcrumb.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';
import {ButtonModule} from 'primeng/button';
import {RadioButtonModule} from 'primeng/radiobutton';
import {AppLoginComponent} from './pages/app.login.component';
/*import {CountryService} from './demo/service/countryservice';
import {CustomerService} from './demo/service/customerservice';
import {EventService} from './demo/service/eventservice';
import {IconService} from './demo/service/iconservice';
import {NodeService} from './demo/service/nodeservice';
import {PhotoService} from './demo/service/photoservice';
import {ProductService} from './demo/service/productservice';
import {CarService} from './demo/service/carservice';*/

//import { AssessmentVisitService } from './demo/service/assessmentvisitservice';
//import { ServiceDetailsService } from './demo/service/servicedetails.service';
//import { ServiceStaffDetailsService } from './demo/service/servicestaffdetails.service';
//import { ServiceNomSubDetailService } from './demo/service/servicenomsupdetails.service';
//import { ServiceProviderService } from './demo/service/serviceprovider.service';
//import { NQAITSSyncService } from './demo/service/nqaitsService.service';
//import { ServiceContextTypesService } from './demo/service/servicecontexttypes.service';
//import { AttachmentsService } from './demo/service/attachments.service';
//import { EmailtemplateService } from './demo/service/emailtemplate.service';
//import { EvidenceService } from './demo/service/evidence.service';
//import { ExternalAccessService } from './demo/service/externalaccess.service';
//import { TrimService } from './demo/service/trimService';
/*import { ElementsService } from './demo/service/elements.service';
import { StandardQuestionsService } from './demo/service/standardquestions.service';
import { StandardsThemesService } from './demo/service/standardsthemes.service';
import { QipDetailsService } from './demo/service/qip-details.service';
import { StdLawRegLinkService } from './demo/service/stdlawreglinkservice';
import { StandardElementsLinkService } from './demo/service/standardelementslink.service';
import { SummaryDetailsService } from './demo/service/summarydetails.service';
import { ClarificationDetailsService } from './demo/service/clarificationdetails.service';
import { StdLawRegulationsService } from './demo/service/stdlawregulations.service';
import { FdcPremisesService } from './demo/service/fdcpremises.service';
import { TrimStructureService } from './demo/service/trimstructure.service';
import { VisitQaStdLinkService } from './demo/service/visitqastdlink.service';*/
import {AppBreadcrumbService} from './app.breadcrumb.service';
import {MenuService} from './app.menu.service';
import { LoginDataService } from './esamui/service/loginData.service';
import { AppConfigService } from './esamui/service/AppConfigService';
//import {DialogModule} from 'primeng/dialog';
//import {DropdownModule} from 'primeng/dropdown';
//import {CalendarModule} from 'primeng/calendar';
//import {TooltipModule} from 'primeng/tooltip';
//import { EditorModule } from 'primeng/editor';
//import {FieldsetModule} from 'primeng/fieldset';
//import {MultiSelectModule} from 'primeng/multiselect';
import { AppExternalAccessComponent } from './pages/app.externalaccess.component';
import { AppExtWelcomeComponent } from './pages/app.extwelcome.component';
import { ConfirmationMessageComponent } from './pages/app.confirmationmsg.component';
import { AppSurveyComponent } from './pages/app.survey.component';
import { Router } from '@angular/router';

export function initConfig(appConfig: AppConfigService) {
    return () => appConfig.loadConfig();
  }

@NgModule({
    imports: [
        BrowserModule,
        ButtonModule,
        //CommonModule,
       CheckboxModule,
       // DialogModule,
        // EditorModule,
        // MultiSelectModule,
        // FieldsetModule,
       InputTextModule,
        TooltipModule,
        ToolbarModule,
      //  DropdownModule,
        FormsModule,
        RadioButtonModule,
      //  CalendarModule,
        TableModule,
        MessageModule,
        MessagesModule,
        StepsModule,
        ProgressSpinnerModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        //ButtonModule,
        VirtualScrollerModule,
        AppCodeModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,
        AppBreadcrumbComponent,
        AppTopBarComponent,
        AppFooterComponent,      
        OAuth2ComponentComponent, 
      //  VisitListComponent, 
       //HomeComponent,     
        AppLoginComponent,
        AppSurveyComponent,
        ConfirmationMessageComponent,
        AppExternalAccessComponent,
        AppExtWelcomeComponent
       
    ],
    providers: [
        {provide: LocationStrategy, useClass: PathLocationStrategy},
      //  CountryService, CarService, CustomerService, EventService, IconService, NodeService,
       // AssessmentVisitService,
      //   ServiceDetailsService, ServiceStaffDetailsService,NQAITSSyncService,StandardElementsLinkService,
       // AttachmentsService,FdcPremisesService,EmailtemplateService,ElementsService, StandardQuestionsService,
       // StandardsThemesService,EvidenceService,QipDetailsService,SummaryDetailsService,ClarificationDetailsService,
       // StdLawRegLinkService, StdLawRegulationsService,TrimStructureService,VisitQaStdLinkService, TrimService,
      //  ServiceNomSubDetailService, ServiceProviderService, ServiceContextTypesService,ExternalAccessService,
       // PhotoService, ProductService, 
        MenuService, AppBreadcrumbService,MessageService,ConfirmationService,
        DatePipe,CookieService,LoginDataService,
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [AppConfigService],
            multi: true,
          },
          {
            provide: HTTP_INTERCEPTORS, 
            useClass: TokenInterceptorService, 
            multi: true
          }
    ],
    bootstrap: [AppComponent],
    schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ]
  })
export class AppModule { }
