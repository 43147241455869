import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

import { AppConfigService } from '../../esamui/service/AppConfigService';
//import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class SSOLogonService {
  uiurl :any;
  ssourl:any;
 // ssoLogoutURL:any;
  ssosecret:any;
  verifier:any;
  clientId:any;
  constructor(private http: HttpClient,private config: AppConfigService) { 
    //this.uiurl = "http://localhost:4200";
   // this.ssosecret = "0Zs5Eldbq0x7kCVVrCMsAj0ewMYqPIa0";
    //this.ssourl = "https://sso.test.det.nsw.edu.au/sso";

    this.uiurl = this.config.getConfig().uiurl;
    this.ssosecret = this.config.getConfig().ssosecret;
    this.ssourl = this.config.getConfig().ssoURL;
    this.clientId = this.config.getConfig().clientId;
   // this.ssoLogoutURL = this.config.getConfig().ssoLogoutURL;
  }

  
  cookieValue:any
  getLogin() {
    // tslint:disable-next-line:max-line-length

    /*const httpOptions = {
      headers: new HttpHeaders({
        'Accept-API-Version':  'resource=3.1, protocol=1.0',
          'iPlanetDirectoryPro': 'my-auth-token',
          'Content-Type': 'application/json',
          'Accept': 'application/json',
      })
    };*/
       
   
    function getNonce(length = 10) {
      const possibleCharacters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_';
      if (length < 6) {
        length = 10;
      }

      let nonce = '';
      for (let i = 0; i < length; i++) {
        // @ts-ignore
        nonce += possibleCharacters.charAt(Math.floor(Math.random() * (64 - 0)) + 0);
      }

      return nonce;
    }

   /*"baseUrl": "http://localhost:8080",
    "uiurl":"http://localhost:4200",
    "trimurl":"https://ece-trim-api-ece-census.cloudapps.test.nsw.education",
    "ssoURL":"https://sso.test.det.nsw.edu.au/sso",
    "ssosecret":"0Zs5Eldbq0x7kCVVrCMsAj0ewMYqPIa0",*/

    const clientId = this.clientId;
    //const clientSecret = '0Zs5Eldbq0x7kCVVrCMsAj0ewMYqPIa0';
    const scope = 'openid+profile+email';
    const responseType = 'code';
    // const redirectUri = 'http://censusui.cloudapps.nsw.education:4200/';
    const redirectUri = this.uiurl + '/secure/aad';
    const oauthServerUrl = this.ssourl + "/authorize";
    const verifier = btoa(''+this.strRandom(32));
    console.log('verifier'+verifier)
    localStorage.setItem('verifier',verifier);
    const nonce = getNonce();
    //const url = `${ oauthServerUrl }?response_type=${ responseType }&client_id=${ clientId }&scope=${ scope }&nonce=${ nonce }&redirect_uri=${ redirectUri }`;
    const url = `${ oauthServerUrl }?response_type=${ responseType }&client_id=${ clientId }&code_challenge_method=plain&code_challenge=${ verifier }&scope=${ scope }&nonce=${ nonce }&redirect_uri=${ redirectUri }`; 
    console.log("this is return url ="+ url);
    // window.location.href='https://sso.dev.det.nsw.edu.au/sso/UI/Login?goto=https://censusui.cloudapps.dev.nsw.education/';
    window.location.href=url;

    // const responseType = { response: new } responseType = 'json';
    // return this.http.post<JSON>('https://sso.dev.det.nsw.edu.au/sso/json/sessions?_action=validate', null, httpOptions)
      // .pipe(map(result => result.json()))
    //  ;

    // return this.http.post('http://localhost:8080/child/location');
    // return this.http.get(localUrl);

  }

 /* getData(cookieval : any) {
  
    console.log("cookieValue"+cookieval);
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept-API-Version':  'resource=3.1, protocol=1.0',
          'iPlanetDirectoryPro': cookieval,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
      })
    };
    return this.http.get('https://sso.dev.det.nsw.edu.au/sso/identity/attributes',httpOptions);
  }*/
 
  getLogout() {
   localStorage.removeItem('displayLoginName');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('firstName');
    localStorage.removeItem('surname');
    localStorage.removeItem('status');
    localStorage.removeItem('roleId');
    localStorage.removeItem('uid');
    //window.location.href=this.ssourl+'/logout?goto='+this.uiurl+'/oauth2/logout'; //TODO:
    let login_hint = localStorage.getItem("login_hint");
    this.http.get<any>(this.ssourl+'/logout?logout_hint='+login_hint).subscribe(res=>{
      console.log("res"+res)
    },err=>{
      console.log("err"+JSON.stringify(err));
    })
   
  }
  private strRandom(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

  getLoggedSSOUserAttributes(cookieValue: string): Observable<JSON> {
    // tslint:disable-next-line:max-line-length

    const httpOptions = {
      headers: new HttpHeaders({
        'Accept-API-Version':  'resource=3.1, protocol=1.0',
        'iPlanetDirectoryPro': cookieValue,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      })
    };
    // const responseType = { response: new } responseType = 'json';
    return this.http.post<any>(this.ssourl+'/identity/attributes', null, httpOptions)
      // .pipe(map(result => result.json()))
      ;

    // return this.http.post('http://localhost:8080/child/location');
    // return this.http.get(localUrl);
  }

  getOAuth2AccessToken (code: string): Observable<JSON> {
    // tslint:disable-next-line:max-line-length
    let verifier = localStorage.getItem('verifier');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
      })
    };
    // const responseType = { response: new } responseType = 'json';
    const body = new HttpParams()
      .set('code', code)
      .set('grant_type', 'authorization_code')
      .set('client_id', this.clientId)
     // .set('client_secret', '0Zs5Eldbq0x7kCVVrCMsAj0ewMYqPIa0')
     .set('code_verifier', verifier)
     .set('redirect_uri', this.uiurl + '/secure/aad');
    return this.http.post<any>(this.ssourl+'/token', body.toString(), httpOptions)
      // .pipe(map(result => result.json()))
      ;

    // return this.http.post('http://localhost:8080/child/location');
    // return this.http.get(localUrl);
  }

  
}
